@import url('https://fonts.googleapis.com/css2?family=Gravitas+One&family=Poppins:wght@400;700&family=Roboto+Condensed:ital,wght@0,400;0,700;1,400;1,700&display=swap');

/* font-family: 'Gravitas One', cursive;
font-family: 'Poppins', sans-serif;
font-family: 'Roboto Condensed', sans-serif; */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  font-family: 'Roboto Condensed', sans-serif;
  background-color: #282c34;
  min-height: 100vh;
}